import React, { MouseEventHandler } from 'react'

import { SidebarContainer, SidebarContentContainer } from './styles'

interface Props {
    isVisible: boolean
    top?: string | 0
    handleOutsideClick?: MouseEventHandler<HTMLElement>
    maxWidth?: string
    zIndex?: string
}

const _handleSidebarContentClick: MouseEventHandler<HTMLElement> = event => {
    event.stopPropagation()
}

const Sidebar: React.FC<Props> = ({ isVisible, handleOutsideClick, top, zIndex, maxWidth, children }) => (
    <SidebarContainer isVisible={isVisible} onClick={handleOutsideClick} top={top} zIndex={zIndex}>
        <SidebarContentContainer isVisible={isVisible} onClick={_handleSidebarContentClick} maxWidth={maxWidth}>
            {children}
        </SidebarContentContainer>
    </SidebarContainer>
)

export default Sidebar
