class LandingPageVersionService {
    static readonly germanSiteRegex = /(https:\/\/www\.|https:\/\/|www\.)?(traum-ferienwohnungen\.)(de|at|ch)\/(vermieten)\/?/
    static readonly englishSiteRegex = /(https:\/\/www\.|https:\/\/|www\.)?(vacation-apartments\.com|holiday-apartments\.co\.uk)\/(list-rental)\/?/

    public static checkLandingPageVersion(referrer: string) {
        if (LandingPageVersionService.germanSiteRegex.test(referrer)) {
            return '2.0.1'
        } else if (LandingPageVersionService.englishSiteRegex.test(referrer)) {
            return '1.0.0'
        } else if (referrer === 'https://homeowner-portal.svc.staging.tfw.io/home') {
            return '2.0.1'
        } else {
            return null
        }
    }
}

export default LandingPageVersionService
