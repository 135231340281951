import { useEffect } from 'react'

import { classicClient, eventTrackingClient } from '@common/clients'
import { EventBeaconEvent } from '@common/transports/event-beacon-transport'
import { LandingPageVersionService } from '@common/services'

export const useTrackUserEntrance = (eventName: EventBeaconEvent) => {
    useEffect(() => {
        ;(async () => {
            try {
                await classicClient.sendEventBeacon({ event: eventName })
                await eventTrackingClient.trackUserEntrance({
                    body: {
                        site: location.href,
                        referrer: document.referrer,
                        siteVersion: location.href
                            ? LandingPageVersionService.checkLandingPageVersion(location.href)
                            : null,
                        referrerVersion: null,
                    },
                })
            } catch (error) {
                console.error(error)
            }
        })()
    }, [eventName])
}
