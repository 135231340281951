import HeadTransport from '@common/transports/common/head-transport'

type HeadModel = HeadTransport

class PageModel {
    public readonly head: HeadModel

    constructor({ head }: { head: HeadModel }) {
        this.head = head
    }
}

export default PageModel
