import React, { CSSProperties, FC, Fragment, useMemo } from 'react'
import Head from 'next/head'
import { WithContext, Product } from 'schema-dts'

import { HeadTransport, MetaTagTransport } from '@common/transports/common/head-transport'
import config from '@common/config'
import isTraumApp from '@common/utils/is-traum-app'

interface PageProps {
    head: HeadTransport
    logoUrl?: string
}

const _style: CSSProperties = { display: 'none', visibility: 'hidden' }

const _getTagManagerHTML = (containerId: string) => ({
    __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}

        if (localStorage.getItem('consentMode') === null) {
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied',
                'functionality_storage': 'denied',
                'personalization_storage': 'denied',
                'security_storage': 'denied',
            });
        } else {
            gtag('consent', 'default', JSON.parse(localStorage.getItem('consentMode')));
        }

        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${containerId}');`,
})

const _getGoogleLoginPopupHTML = (accountsGoogleLoginUrl: string) => ({
    __html: `<script>
                function dispatchGoogleLoginEvent(action) {
                    const googleLoginEvent = new CustomEvent('customActionEvent', {
                        detail: { type: 'social_login', action: action },
                        bubbles: true,
                        cancelable: true,
                        composed: false,
                    });
                    window.dispatchEvent(googleLoginEvent);
                }

                function socialLoginCallback(notification) {
                    if (notification.isDisplayed()) {
                        dispatchGoogleLoginEvent('display');
                    }

                    if (notification.isDismissedMoment()) {
                        dispatchGoogleLoginEvent('click');
                    }

                    if (notification.getDismissedReason() === 'credential_returned') {
                        dispatchGoogleLoginEvent('success');
                    }
                }
            </script>

            <div
                id="g_id_onload"
                data-client_id="424077213000-vkgfpmlv150gtg2cc7u4r6rk8q692qrl.apps.googleusercontent.com"
                data-context="signin"
                data-ux_mode="popup"
                data-moment_callback="socialLoginCallback"
                data-login_uri='${accountsGoogleLoginUrl}'
                data-itp_support="true"
                data-cancel_on_tap_outside="true"
                data-skip_prompt_cookie="heracles_oauth_device_verifier"
            ></div>`,
})

const zESettings = {
    __html: `
    window.zESettings = {
        errorReporting: true,
        webWidget: {
            offset: {
                horizontal: "20px",
                vertical: "20px",
                mobile: {
                    horizontal: "0px",
                    vertical: "60px",
                },
            },
            chat: {
                departments: {
                    select: "supply"
                },
                hideWhenOffline: true
            },
            color: {
                theme: '#fff',
                launcher: '#66b6e5',
                launcherText: '#fff',
                button: '#fdc400',
                header: '#000',
            },
            launcher: {
                chatLabel: {
                    '*': 'Chatten'
                }
            },
        }
    };
  `,
}

const _getProductSchemaHTML = (
    metaTags: MetaTagTransport[],
    title: string,
    logoUrl?: string,
): { __html: string } | null => {
    const schema: WithContext<Product> = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: title,
        image: logoUrl,
        description: metaTags[0].content,
        brand: {
            '@type': 'Brand',
            name: 'Traum Ferienwohnungen',
        },
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: '4.2',
            bestRating: '5',
            worstRating: '1',
            ratingCount: '1102',
            Author: {
                '@type': 'Organization',
                name: 'By Trustpilot',
            },
        },
    }

    return {
        __html: JSON.stringify(schema),
    }
}

const loSettings = {
    __html: `
      window.__lo_site_id = 330311;
    `,
}

const Page: FC<PageProps> = ({ head, children, logoUrl }) => {
    const { title, metaTags, linkTags } = head

    const _tagManagerHtml = useMemo(() => _getTagManagerHTML(config.gtmContainerId), [])
    const _googleLoginPopupHTML = useMemo(() => _getGoogleLoginPopupHTML(config.accountsGoogleLoginUrl), [])
    const _productSchemaHTML = useMemo(
        () => _getProductSchemaHTML(metaTags, title, logoUrl),
        [metaTags, title, logoUrl],
    )

    return (
        <Fragment>
            <Head>
                <title>{title}</title>
                {!!metaTags && metaTags.map((metaTag, index) => <meta key={`meta_${index}`} {...metaTag} />)}
                {!!linkTags && linkTags.map((linkTag, index) => <link key={`link_${index}`} {...linkTag} />)}
                {/* Google Tag Manager */}
                <script dangerouslySetInnerHTML={_tagManagerHtml} />
                {/* End Google Tag Manager */}

                {/* HO chat. */}
                <script dangerouslySetInnerHTML={zESettings} />
                <script
                    id="ze-snippet"
                    src="https://static.zdassets.com/ekr/snippet.js?key=67635b6d-693a-4aca-bac7-3b299ef60186"
                />

                {/* Lucky Orange */}
                {config.environment === 'production' && (
                    <>
                        <script dangerouslySetInnerHTML={loSettings} />
                        <script
                            id="lucky-orange"
                            src="https://d10lpsik1i8c69.cloudfront.net/w.js"
                            type="text/javascript"
                            async
                            defer
                        />
                    </>
                )}
                <script type="application/ld+json" dangerouslySetInnerHTML={_productSchemaHTML} />
            </Head>
            {/* social login popup Google */}
            {isTraumApp() ? null : <div dangerouslySetInnerHTML={_googleLoginPopupHTML} />}

            {/* Google Tag Manager (noscript) */}
            <noscript>
                <iframe
                    src={`https://www.googletagmanager.com/ns.html?id=${config.gtmContainerId}`}
                    height="0"
                    width="0"
                    style={_style}
                />
            </noscript>
            {/* End Google Tag Manager (noscript) */}

            {children}
        </Fragment>
    )
}

export default Page
