import CheckDeviceSessionTransport from '@common/transports/check-device-session-transport'

class CheckDeviceSessionModel {
    public readonly userId: string

    constructor({ userId }: { userId: string }) {
        this.userId = userId
    }

    public static buildFromResponse(data: CheckDeviceSessionTransport): CheckDeviceSessionModel {
        return new CheckDeviceSessionModel(data)
    }
}

export default CheckDeviceSessionModel
