import { BuilderMethod } from '@src/models/types'
import HomePageModel from '@src/models/home-page-model'
import { isServer } from '@common/utils/platform-utils'
import { CheckDeviceSessionModel } from '@common/models'

const _transformableModels = new Map<string, BuilderMethod>([
    ['pages/home', HomePageModel.buildFromResponse],
    ['check-device-session', CheckDeviceSessionModel.buildFromResponse],
])

/**
 *
 * @param resourceType The REST resource name that this transport maps to.
 * @param data The actual transport itself.
 */
export const transformTransportData = (resourceType: string | null, data: any[] | any) => {
    if (resourceType && !isServer) {
        const builder = _transformableModels.get(resourceType)
        if (builder) {
            return Array.isArray(data) ? data.map(data => builder(data)) : builder(data)
        }
    }
    return data
}

export const getModel = <T extends object, M extends object>(data: T, meta?: { resource?: string }): M => {
    const resourceType = meta && meta.resource ? meta.resource : null
    return transformTransportData(resourceType, data) as M
}
