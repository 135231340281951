import DeprecatedHomePageTransport, {
    BenefitsSectionTransport,
    CustomerSupportFooterSectionTransport,
    CustomerSupportSectionTransport,
    FAQSectionTransport,
    FooterSectionTransport,
    HeaderSectionTransport,
    HeroSectionTransport,
    HowItWorksSectionTransport,
    MoneyBackGuaranteeSectionTransport,
    TestimonialsSectionTransport,
} from '@common/transports/deprecated-home-page-transport'
import PageModel from './page-model'
import HeadTransport from '@common/transports/common/head-transport'

class HomePageModel extends PageModel {
    public readonly headerSection: HeaderSectionTransport
    public readonly heroSection: HeroSectionTransport
    public readonly customerSupportSection: CustomerSupportSectionTransport
    public readonly benefitsSection: BenefitsSectionTransport
    public readonly testimonialsSection: TestimonialsSectionTransport
    public readonly howItWorksSection: HowItWorksSectionTransport
    public readonly moneyBackGuaranteeSection?: MoneyBackGuaranteeSectionTransport
    public readonly faqSection: FAQSectionTransport
    public readonly customerSupportFooterSection: CustomerSupportFooterSectionTransport
    public readonly footerSection: FooterSectionTransport

    constructor({
        headerSection,
        heroSection,
        customerSupportSection,
        benefitsSection,
        testimonialsSection,
        howItWorksSection,
        moneyBackGuaranteeSection,
        faqSection,
        customerSupportFooterSection,
        footerSection,
        head,
    }: {
        headerSection: HeaderSectionTransport
        heroSection: HeroSectionTransport
        customerSupportSection: CustomerSupportSectionTransport
        benefitsSection: BenefitsSectionTransport
        testimonialsSection: TestimonialsSectionTransport
        howItWorksSection: HowItWorksSectionTransport
        moneyBackGuaranteeSection?: MoneyBackGuaranteeSectionTransport
        faqSection: FAQSectionTransport
        customerSupportFooterSection: CustomerSupportFooterSectionTransport
        footerSection: FooterSectionTransport
        head: HeadTransport
    }) {
        super({ head })
        this.headerSection = headerSection
        this.heroSection = heroSection
        this.customerSupportSection = customerSupportSection
        this.benefitsSection = benefitsSection
        this.testimonialsSection = testimonialsSection
        this.howItWorksSection = howItWorksSection
        this.moneyBackGuaranteeSection = moneyBackGuaranteeSection
        this.faqSection = faqSection
        this.customerSupportFooterSection = customerSupportFooterSection
        this.footerSection = footerSection
    }

    public static buildFromResponse(data: DeprecatedHomePageTransport): HomePageModel {
        return new HomePageModel(data)
    }
}

export default HomePageModel
