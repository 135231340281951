import { SerializableObject } from 'react-components'

export enum EventBeaconEvent {
    LANDING_PAGE_INDEX = 'landingpages_index',
}

interface EventBeaconTransport extends SerializableObject {
    event: EventBeaconEvent
}

export default EventBeaconTransport
