import { createSelector } from 'reselect'

import State from './types'
import CheckDeviceSessionModel from '@common/models/check-device-session-model'
import RootState, { TransportResponseState } from '../types'
import CheckDeviceSessionTransport from '@common/transports/check-device-session-transport'
import BaseTransport from '@common/transports/common/base-transport'
import { getModel } from '@src/utils/model-utils'

const _getUser = (state: RootState): State => state.user

const _getCheckDeviceSessionTransportResponseState = createSelector<
    RootState,
    State,
    TransportResponseState<BaseTransport<CheckDeviceSessionTransport>>
>(_getUser, user => user.checkDeviceSession)

const _getBaseCheckDeviceSessionTransport = createSelector<
    RootState,
    TransportResponseState<BaseTransport<CheckDeviceSessionTransport>>,
    BaseTransport<CheckDeviceSessionTransport> | null
>(_getCheckDeviceSessionTransportResponseState, user => user.transport)

export const getCheckDeviceSessionModel = createSelector<
    RootState,
    BaseTransport<CheckDeviceSessionTransport> | null,
    CheckDeviceSessionModel | null
>(_getBaseCheckDeviceSessionTransport, transport =>
    transport ? getModel<CheckDeviceSessionTransport, CheckDeviceSessionModel>(transport.data, transport.meta) : null,
)

export const getIsUserLoggedIn = createSelector<RootState, CheckDeviceSessionModel | null, boolean>(
    getCheckDeviceSessionModel,
    checkDeviceSessionModel => !!checkDeviceSessionModel && !!checkDeviceSessionModel.userId,
)
