import styled from 'styled-components'
import { desktopBreakpoint, wideScreenBreakpoint } from 'react-components'

interface SidebarContainerProps {
    isVisible: boolean
    top?: string | 0
    zIndex?: string
}

export const SidebarContainer = styled.div<SidebarContainerProps>`
    z-index: ${({ zIndex }) => zIndex ?? 1000};
    background-color: ${({ theme }) => theme.colors.tfwBlack87}cc;
    width: 100vw;
    position: fixed;
    bottom: 0;
    top: ${({ theme, top }) => (top !== null && top !== undefined ? top : theme.layout.large)};
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        display: none;
    }
`

interface SidebarContentContainerProps {
    isVisible: boolean
    maxWidth?: string
}

export const SidebarContentContainer = styled.nav<SidebarContentContainerProps>`
    position: absolute;
    right: 0;
    z-index: 1001;
    height: 100%;
    width: 87%;
    ${props => props.maxWidth && `max-width: ${props.maxWidth};`};
    background-color: ${props => props.theme.colors.white};

    ${({ isVisible }) =>
        isVisible
            ? `
        visibility: visible;
        transition: all 300ms ease-in;
    `
            : `
        visibility: hidden;
        transition: all 300ms ease-out;
        transform: translate(100%, 0);
    `}
`
