/**
 * This file does NOT follow the general convention of separating clients on the basis of features. Rather it represents
 * a client for a particular service. This is done assuming there won't be many APIs here. If there are, then this
 * should be separated on the basis of features instead.
 */

import { NetworkService } from '@common/services'
import config from '@common/config'
import EventBeaconTransport from '@common/transports/event-beacon-transport'

class ClassicClient {
    private readonly _networkService: NetworkService
    private readonly _basePath: string

    constructor(baseUri: string, basePath: string) {
        this._networkService = new NetworkService(baseUri)
        this._basePath = basePath
    }

    public sendEventBeacon(eventBeacon: EventBeaconTransport): Promise<EventBeaconTransport> {
        return this._networkService.post({
            body: eventBeacon,
            restConfig: { withCredentials: true },
            url: `${this._basePath}/api/event-beacon/`,
            shouldHandleNetworkAndServerError: false,
        })
    }
}

export default new ClassicClient(config.classicBaseUrl, '')
